import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/eIpcN6oTGNM"
    bibleGroupSrc="https://www.docdroid.net/EuXUiJF/bible-group-homework-9-6-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="https://bible.com/events/39018359"
  >
    <SEO title="Malachi - Now What?" />
  </Layout>
)

export default SermonPost
